import React from 'react'
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image-es5'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag='section'
      fluid={data.desktop.childImageSharp.fluid}
      css={tw`min-h-50 sm:min-h-75 justify-center items-center flex-col flex`}>
      <h1
        css={tw`font-heading text-center text-4xl md:text-6xl font-semibold text-red-800 bg-white bg-opacity-50 p-4 rounded`}>
        Welcome to Sherry&apos;s Cafe!
      </h1>
      <div css={tw`pb-8 sm:pb-0 pt-8 sm:pt-4`}>
        <a
          href='https://order.sherryscafetrussville.com'
          target='_blank'
          rel='noreferrer'>
          <div
            css={tw`bg-red-800 rounded-lg text-2xl sm:text-4xl text-white pl-4 pr-4 pt-2 pb-2 text-center font-semibold align-baseline`}>
            Order Online
          </div>
        </a>
      </div>
    </BackgroundImage>
  )
}

export default Hero
