import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo/SEO'
import H2 from '../components/styled/H2'
import H3 from '../components/styled/H3'

import Hero from '../components/hero/Hero'
import Store from '../components/images/Store'
import Shortcake from '../components/images/Shortcake'
import Meal from '../components/images/Meal'

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <Hero />
    <div css={tw`sm:grid sm:grid-cols-3`}>
      <div
        css={tw`p-3 sm:p-0 flex items-center justify-center flex-col bg-red-800 bg-opacity-75 text-white text-center`}>
        <H2 css={tw`text-5xl text-white underline`}>Hours</H2>
        <ul>
          <li>
            <H3 css={tw`text-white`}>Monday-Friday</H3>
            <ul css={tw`p-3`}>
              <time itemProp='openingHours' dateTime='Mo-Fr 11:00-19:00'>
                <li>11:00am-7:00pm</li>
              </time>
            </ul>
          </li>
          <li>
            <H3 css={tw`text-white`}>Saturday</H3>
            <ul css={tw`p-3`}>
              <time itemProp='openingHours' dateTime='Sa 11:00-14:00'>
                <li>11:00am-2:00pm</li>
              </time>
            </ul>
          </li>
          <li>
            <H3 css={tw`text-white`}>Sunday</H3>
            <ul css={tw`p-3`}>
              <time itemProp='openingHours' dateTime='Su 10:30-14:30'>
                <li>10:30am-2:30pm</li>
              </time>
            </ul>
          </li>
        </ul>
      </div>
      <div css={tw`sm:col-span-2`}>
        <Store />
      </div>
    </div>
    <div css={tw`flex flex-col-reverse sm:grid sm:grid-cols-3`}>
      <div css={tw`sm:col-span-2`}>
        <Shortcake />
      </div>
      <div
        css={tw`p-3 sm:p-0 flex items-center justify-center flex-col bg-teal-700 bg-opacity-75 text-white text-center`}>
        <p css={tw`text-xl`}>Check out todays menu!</p>
        <a
          href='./pdfs/menu.pdf'
          target='_blank'
          rel='noreferrer'
          css={tw`text-xl`}>
          <H2 css={tw`text-2xl bg-red-800 text-white p-2 rounded mt-3`}>
            Menu
          </H2>
        </a>
      </div>
    </div>
    <div css={tw`sm:grid sm:grid-cols-3`}>
      <div
        css={tw`p-5 sm:p-0 flex items-center justify-center flex-col bg-pink-800 bg-opacity-75 text-white text-center`}>
        <H2 css={tw`text-5xl text-white underline`}>Location</H2>
        <address css={tw`text-2xl`}>
          5800 Valley Rd Suite 110
          <br />
          Trussville, AL 35173
        </address>
      </div>
      <div css={tw`sm:col-span-2`}>
        <Meal />
      </div>
    </div>
    <div css={tw`flex justify-center p-4`}>
      <div css={tw`max-w-4xl`}>
        <video controls>
          <source src='./video/sherrys-cafe.mp4' type='video/mp4' />
        </video>
      </div>
    </div>
  </Layout>
)

export default IndexPage
